import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdGroups, MdHandshake, MdRestaurant, MdVolunteerActivism } from 'react-icons/md';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './VolunteeringInfoSection.module.scss';
const VolunteeringInfoSection = ({ className }) => {
    const { t } = useTranslation();
    return (_jsxs("section", { className: clsx(styles.volunteeringInfoSection, className), children: [_jsx("p", { className: styles.description, children: t('join_intro_page.volunteering_info.description') }), _jsxs("div", { className: styles.pointsContainer, children: [_jsxs("div", { className: styles.layout, children: [_jsxs("div", { className: styles.pointWrapper, children: [_jsx("span", { className: styles.icon, children: _jsx(MdRestaurant, { "data-testid": "restaurantIcon" }) }), _jsx("p", { children: t('join_intro_page.volunteering_info.point_1') })] }), _jsxs("div", { className: styles.pointWrapper, children: [_jsx("span", { className: styles.icon, children: _jsx(MdHandshake, { "data-testid": "handshakeIcon" }) }), _jsx("p", { children: t('join_intro_page.volunteering_info.point_2') })] })] }), _jsxs("div", { className: styles.layout, children: [_jsxs("div", { className: styles.pointWrapper, children: [_jsx("span", { className: styles.icon, children: _jsx(MdVolunteerActivism, { "data-testid": "volunteerActivismIcon" }) }), _jsx("p", { children: t('join_intro_page.volunteering_info.point_3') })] }), _jsxs("div", { className: styles.pointWrapper, children: [_jsx("span", { className: styles.icon, children: _jsx(MdGroups, { "data-testid": "groupsIcon" }) }), _jsx("p", { children: t('join_intro_page.volunteering_info.point_4') })] })] })] })] }));
};
VolunteeringInfoSection.propTypes = {
    className: PropTypes.string,
};
export default VolunteeringInfoSection;
