import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { RECOMMENDATION } from 'config/alert';
import DisplayedCollection from 'components/JoinIntro/DisplayedCollection';
import { collectionPropType } from 'components/JoinIntro/DisplayedCollection/DisplayedCollection';
import Alert from 'components/common/Alert';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import styles from './CollectionsOverview.module.scss';
const CollectionsOverview = ({ adhocCollection, ongoingCollection }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.collectionOverview, children: [_jsx("h3", { className: styles.header, children: t('join-intro-page.collections-overview.header') }), _jsx("p", { className: styles.description, children: t('join-intro-page.collections-overview.description') }), _jsxs("div", { className: styles.collections, children: [_jsxs("div", { className: clsx(styles.collection, styles.adhocCollection), children: [_jsx("p", { className: clsx(styles.adhocCollectionDescription, styles.collectionDescription), children: _jsx(SetInnerHtmlSafe, { children: t('join-intro-page.collections-overview.adhoc-collection.description.text', {
                                        'bold-text': `<b>${t('join-intro-page.collections-overview.adhoc-collection.description.bold-text')}</b>`,
                                    }) }) }), _jsx(DisplayedCollection, { collection: adhocCollection })] }), _jsxs("div", { className: styles.collection, children: [_jsx("p", { className: clsx(styles.ongoingCollectionDescription, styles.collectionDescription), children: _jsx(SetInnerHtmlSafe, { children: t('join-intro-page.collections-overview.ongoing-collection.description.text', {
                                        'bold-text': `<b>${t('join-intro-page.collections-overview.ongoing-collection.description.bold-text')}</b>`,
                                    }) }) }), _jsx(DisplayedCollection, { collection: ongoingCollection })] })] }), _jsx(Alert, { variant: RECOMMENDATION, children: t('join-intro-page.collections-overview.alert-message') })] }));
};
CollectionsOverview.propTypes = {
    adhocCollection: collectionPropType.isRequired,
    ongoingCollection: collectionPropType.isRequired,
};
export default CollectionsOverview;
