import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAppDispatch } from '@redux/hooks';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import updateUserPreferences from '@redux/thunks/preferences/updateUserPreferences';
import Debug from 'helpers/debug';
import Button from 'components/common/Button';
import Card from 'components/common/Cards/Card';
import styles from './DismissableCard.module.scss';
const debug = Debug('components:common:cards:dismissable-card');
const DismissableCard = ({ heading, image, children, buttonText, buttonLink, userPreferenceToUpdate, handleActionClick, handleDismissClick, }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const handleDismissButtonClick = async () => {
        try {
            await dispatch(updateUserPreferences({ [userPreferenceToUpdate]: true }));
            await dispatch(fetchUserPreferences());
            if (handleDismissClick !== undefined) {
                handleDismissClick();
            }
        }
        catch {
            return debug;
        }
    };
    const handleActionButtonClick = () => {
        if (handleActionClick !== undefined) {
            handleActionClick();
        }
    };
    return (_jsxs(Card, { children: [_jsx(Card.Heading, { children: heading }), _jsx(Card.Image, { children: image }), _jsx(Card.TextSection, { children: children }), _jsxs("div", { className: styles.buttonContainer, children: [_jsx(Button, { target: "_blank", className: styles.linkButton, href: buttonLink, onClick: handleActionButtonClick, children: buttonText }), _jsx(Button, { className: styles.dismissButton, onClick: handleDismissButtonClick, children: t('common.cards.dismissable-card.dismiss-button') })] })] }));
};
DismissableCard.propTypes = {
    heading: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    userPreferenceToUpdate: PropTypes.string.isRequired,
    handleDismissClick: PropTypes.func,
    handleActionClick: PropTypes.func,
};
export default DismissableCard;
