import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getSignedStaticMapUrl } from 'helpers/map';
import { useIsMobile } from 'hooks/application';
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation';
import styles from './DisplayedCollection.module.scss';
const DisplayedCollection = ({ collection }) => {
    const isMobile = useIsMobile();
    const [mapUrl, setMapUrl] = useState();
    useEffect(() => {
        const getMapUrl = async () => {
            const url = await getSignedStaticMapUrl({
                latitude: collection.storeLocation.latitude,
                longitude: collection.storeLocation.longitude,
                width: 520,
                height: 157,
            });
            setMapUrl(url);
        };
        if (isMobile === false) {
            getMapUrl();
        }
    }, [isMobile, collection.storeLocation]);
    return (_jsx("div", { className: clsx('card', styles.displayedCollection), children: _jsxs("div", { className: "card-content", children: [_jsxs("h3", { className: styles.cardHeader, children: [_jsx("span", { children: collection.businessName }), collection.storeName !== undefined && (_jsxs(_Fragment, { children: [_jsx("span", { children: " | " }), _jsx("span", { children: collection.storeName })] }))] }), _jsx(AvailableCollectionInformation, { type: collection.type, storeLocation: collection.storeLocation, homeLocation: collection.homeLocation, frequency: collection.frequency, nextCollection: collection.nextCollection, storeAddress: collection.storeAddress, businessName: collection.businessName, className: styles.availebleCollectionInformation }), isMobile === false && mapUrl !== undefined && (_jsx("img", { className: styles.mapImage, width: "520", height: "157", alt: "", src: mapUrl, "data-testid": "map-image" }))] }) }));
};
const collectionPropType = PropTypes.shape({
    storeLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
    homeLocation: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }).isRequired,
    frequency: PropTypes.string.isRequired,
    nextCollection: PropTypes.string.isRequired,
    storeAddress: PropTypes.shape({
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        postCode: PropTypes.string,
    }).isRequired,
    businessName: PropTypes.string.isRequired,
    storeName: PropTypes.string,
    type: PropTypes.string.isRequired,
});
DisplayedCollection.propTypes = {
    collection: collectionPropType,
};
export { collectionPropType };
export default DisplayedCollection;
