import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineSchool } from 'react-icons/md';
import clsx from 'clsx';
import styles from './InductionProgress.module.scss';
const InductionProgress = ({ completedInductionsCount, inductionsCount, collectionType, }) => {
    const { t } = useTranslation();
    if (inductionsCount === undefined || completedInductionsCount === undefined) {
        return null;
    }
    return (_jsxs("div", { className: clsx('info', styles.inductionProgress), children: [_jsx("span", { children: _jsx(MdOutlineSchool, { className: clsx('information-icons', collectionType) }) }), _jsxs("span", { className: styles.inductionProgressValues, children: [`${completedInductionsCount}/${inductionsCount} `, t('collections.available.inductions')] })] }));
};
export default InductionProgress;
