import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdClose } from 'react-icons/md';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Check from 'images/checkInCircleColour.svg';
import GenericModal from 'components/common/Modal/GenericModal';
import styles from './SuccessModal.module.scss';
const SuccessModal = ({ onClose, children, modalKey }) => {
    return (_jsxs(GenericModal, { closeModal: onClose, className: styles.successModal, modalKey: modalKey, children: [_jsx("button", { type: "button", className: clsx('no-button-styling', styles.modalClose), onClick: onClose, "aria-label": "Close", children: _jsx(MdClose, { "data-testid": "closeIcon" }) }), _jsx("img", { src: Check, alt: "" }), children] }));
};
SuccessModal.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    modalKey: PropTypes.string.isRequired,
};
export default SuccessModal;
