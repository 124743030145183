import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { STORES_CLEAR_ERROR } from '@redux/actionTypes';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections';
import toggleSquadCaptainRole from '@redux/thunks/stores/toggleSquadCaptainRole';
import fetchUser from '@redux/thunks/users/fetchUser';
import Debug from 'helpers/debug';
import { getReportAnIssueUrl } from 'helpers/typeform';
import { getCurrentUserId } from 'helpers/user';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import Pill from 'components/common/Pill';
import StoreInfo from 'components/common/StoreInfo';
import Rota from 'components/common/StoreRota/Rota';
import UserAvatar from 'components/common/UserAvatar';
import { buttonExpandingAnimationConfig, expandingAnimationConfig } from './animations';
const debug = Debug('components:store:store-card');
const StoreCard = ({ storeId, handleExpandClick, handleExpandKeyDown, isExpanded }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);
    const { businessId, location: storeLocation, squadCaptain, name: storeName, } = useAppSelector((state) => state.stores.items[storeId] ?? {});
    const { id: squadCaptainId } = squadCaptain || {};
    const isSquadCaptain = useMemo(() => {
        return Number(getCurrentUserId()) === Number(squadCaptainId);
    }, [squadCaptainId]);
    const { squadCaptainPhotoUrl, squadCaptainName } = useAppSelector((state) => {
        if (isSquadCaptain) {
            return {
                squadCaptainPhotoUrl: state.users.currentUser.avatar?.small,
                squadCaptainName: state.users.currentUser.name?.firstName,
            };
        }
        return {
            squadCaptainPhotoUrl: state.users.items[squadCaptainId]?.avatar?.small,
            squadCaptainName: state.users.items[squadCaptainId]?.name,
        };
    }, shallowEqual);
    const noSquadCaptain = useMemo(() => {
        return squadCaptainId === null || squadCaptainId === 0;
    }, [squadCaptainId]);
    const business = useAppSelector((state) => state.stores.businesses[businessId], shallowEqual);
    const businessLogo = business?.logo?.url;
    const businessName = business?.name;
    const displaySquadCaptainButton = useMemo(() => {
        return noSquadCaptain === true || isSquadCaptain === true;
    }, [noSquadCaptain, isSquadCaptain]);
    const errors = useAppSelector((state) => state.stores.items[storeId]?.errors);
    useEffect(() => {
        if (storeId !== undefined) {
            dispatch(fetchStoreCollections({ storeId })).catch(debug);
        }
    }, [storeId]);
    useEffect(() => {
        if (squadCaptainId !== undefined && noSquadCaptain === false && isSquadCaptain === false) {
            dispatch(fetchUser(squadCaptainId)).catch(debug);
        }
    }, [squadCaptainId]);
    const reportAnIssueUrl = useMemo(() => {
        return getReportAnIssueUrl({
            t,
            storeName,
            businessName,
            storeId,
            businessId,
            userId: getCurrentUserId(),
        });
    }, [storeName, businessName, storeId, businessId]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "store-card", children: _jsx("div", { children: _jsx(motion.div, { layout: "position", className: "card", children: _jsxs("div", { className: "card-content", children: [storeLocation !== undefined && businessLogo !== undefined && (_jsx(StoreInfo, { logoUrl: businessLogo, location: storeLocation, storeId: storeId })), _jsxs("div", { className: "store-details", children: [_jsxs("div", { children: [_jsxs("div", { className: "pill-with-label", children: [_jsx("label", { children: t('stores.store.store_name') }), _jsx(Pill, { children: _jsx(Pill.Text, { children: _jsxs("span", { children: ["\uD83D\uDEE1\uFE0F ", storeName] }) }) })] }), _jsxs("div", { className: "pill-with-label", children: [_jsx("label", { children: t('stores.store.squad_captain') }), _jsxs(Pill, { children: [squadCaptainPhotoUrl && (_jsx(Pill.Icon, { children: _jsx(UserAvatar, { className: "avatar", imageUrl: squadCaptainPhotoUrl }) })), _jsx("span", { children: _jsx(Pill.Text, { children: squadCaptainName || t('stores.store.none') }) })] })] })] }), _jsx(AnimatePresence, { mode: "wait", children: isExpanded(storeId) === true && (_jsx(_Fragment, { children: _jsxs(motion.div, { className: "rota-with-label", ...expandingAnimationConfig, children: [_jsx("div", { className: "rota-label", children: t('stores.store.squad_captain_collections') }), _jsx(Rota, { storeId: storeId, className: "store-card-rota" }), errors !== undefined && (_jsx(Alert, { variant: "error", children: isSquadCaptain === false
                                                            ? t('stores.store.errors.become_squad_captain_error')
                                                            : t('stores.store.errors.unassign_squad_captain_error') })), displaySquadCaptainButton === true && (_jsx(motion.div, { className: "toggle-squad-captain-role-button-wrapper", ...buttonExpandingAnimationConfig, children: _jsxs(Button, { secondary: true, disabled: isToggleDisabled, onClick: async () => {
                                                                setIsToggleDisabled(() => true);
                                                                dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } });
                                                                await dispatch(toggleSquadCaptainRole({
                                                                    isSquadCaptain,
                                                                    storeId,
                                                                })).catch(debug);
                                                                setIsToggleDisabled(() => false);
                                                            }, children: [noSquadCaptain === true &&
                                                                    t('stores.store.buttons.become_squad_captain'), isSquadCaptain === true &&
                                                                    t('stores.store.buttons.unassign_squad_captain')] }) }))] }) })) })] }), _jsxs(motion.div, { layout: "position", initial: false, "data-testid": `button-wrapper-${storeId}`, className: clsx('inline-button-wrapper', {
                                    collapsed: isExpanded(storeId) === false,
                                }), children: [_jsx(Button, { onClick: (event) => {
                                            handleExpandClick(event, storeId);
                                            dispatch({ type: STORES_CLEAR_ERROR, payload: { storeId } });
                                        }, onKeyDown: (event) => {
                                            handleExpandKeyDown(event, storeId);
                                        }, children: isExpanded(storeId) === true
                                            ? t('stores.store.buttons.close_details')
                                            : t('stores.store.buttons.more_info') }), _jsx(Button, { href: reportAnIssueUrl, secondary: true, children: t('stores.store.something_wrong_text') })] })] }) }) }) }) }));
};
StoreCard.propTypes = {
    storeId: PropTypes.number.isRequired,
    handleExpandClick: PropTypes.func.isRequired,
    handleExpandKeyDown: PropTypes.func.isRequired,
    isExpanded: PropTypes.func.isRequired,
};
export default StoreCard;
