import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { getDistance } from 'geolib';
import { LOAD_AVAILABLE_COLLECTIONS } from '@redux/actionTypes';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { selectBusinessesForAvailableCollections } from '@redux/selectors/businesses';
import fetchMyInductions from '@redux/thunks/inductions/fetchMyInductions';
import { trackEvent } from 'services/Analytics';
import { COLLECTION_TYPES } from 'config/collections';
import { useIsMobile } from 'hooks/application';
import { useSearchParamsState } from 'hooks/application/useSearchParamsState';
import AvailableCollectionsListCard from 'components/AvailableCollections/AvailableCollectionsListCard';
import Dropdown from 'components/common/Dropdown';
import { Grid, Inline, Stack } from 'components/layout/primitives';
import NoCollectionsNearMe from './NoCollectionsNearMe';
const { ONGOING, ADHOC, TEMPORARY } = COLLECTION_TYPES;
const sortByFilterKeys = {
    soonest: 'soonest',
    closest: 'closest',
};
const collectionTypeFilterKeys = {
    ALL: 'all',
    ...COLLECTION_TYPES,
};
const businessFilterKeys = {
    all: 'all',
};
const storeFilterKeys = {
    all: 'all',
};
const filtersDefaults = {
    storeid: { type: 'string', default: storeFilterKeys.all },
    businessid: { type: 'string', default: businessFilterKeys.all },
    type: { type: 'string', default: collectionTypeFilterKeys.ALL },
    sort: { type: 'string', default: sortByFilterKeys.soonest },
};
const AvailableCollectionsList = ({ availableCollections }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const collectionTypeFilterOptionLabels = {
        adhoc: t('collection_slots.index.type_filter.adhoc'),
        ongoing: t('collection_slots.index.type_filter.ongoing'),
        temporary: t('collection_slots.index.type_filter.temporary'),
    };
    const isMobile = useIsMobile();
    const homeLocation = useAppSelector((state) => state.users.currentUser.location);
    const businesses = useAppSelector(selectBusinessesForAvailableCollections);
    const businessFilterOptions = useMemo(() => {
        const availableBusinesses = businesses.map((business) => ({
            value: business?.id,
            label: business?.name,
        }));
        return availableBusinesses.sort((a, b) => (a.label > b.label ? 1 : -1));
    }, [businesses]);
    const stores = useAppSelector((state) => state.stores.items);
    const [searchParams, setSearchParams] = useSearchParamsState(filtersDefaults);
    const storeFilterOptions = useMemo(() => {
        const associatedStores = [];
        for (const [key, store] of Object.entries(stores)) {
            if (store.businessId === Number(searchParams.businessid)) {
                associatedStores.push({ value: key, label: store.name });
            }
        }
        return associatedStores.sort((a, b) => a.label.localeCompare(b.label));
    }, [stores, businesses, searchParams.businessid]);
    const onSortOptionChange = (event) => setSearchParams({ sort: event.target.value });
    const onCollectionTypeChange = (event) => setSearchParams({ type: event.target.value });
    const onBusinessChange = (event) => {
        setSearchParams({
            businessid: event.target.value,
            storeid: storeFilterKeys.all,
        });
    };
    const onStoreChange = (event) => setSearchParams({ storeid: event.target.value });
    const collectionTypeFilterOptions = useMemo(() => {
        const types = availableCollections.map((collection) => collection.type);
        const uniqueTypes = [...new Set(types)];
        return uniqueTypes
            .map((collectionType) => ({
            value: collectionType,
            label: collectionTypeFilterOptionLabels[collectionType],
        }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [availableCollections]);
    const filterByCollectionType = (collection) => {
        switch (searchParams.type) {
            case collectionTypeFilterKeys.ONGOING:
                return collection.type === ONGOING;
            case collectionTypeFilterKeys.ADHOC:
                return collection.type === ADHOC;
            case collectionTypeFilterKeys.TEMPORARY:
                return collection.type === TEMPORARY;
            default:
                return true;
        }
    };
    const filterByBusiness = (collection) => {
        if (searchParams.businessid === businessFilterKeys.all) {
            return true;
        }
        return collection.business?.id === Number(searchParams.businessid);
    };
    const filterByStore = (collection) => {
        if (searchParams.storeid === storeFilterKeys.all) {
            return true;
        }
        return collection.storeId === Number(searchParams.storeid);
    };
    const filteredAndSortedCollections = useMemo(() => {
        return availableCollections
            .filter(filterByCollectionType)
            .filter(filterByBusiness)
            .filter(filterByStore)
            .sort((a, b) => {
            if (!a.store?.location || !b.store?.location) {
                return 0;
            }
            const collectionATime = new Date(a.nextCollection).getTime();
            const collectionBTime = new Date(b.nextCollection).getTime();
            const distanceAccuracyInMeters = 100;
            const collectionADistance = getDistance(homeLocation, a.store.location, distanceAccuracyInMeters);
            const collectionBDistance = getDistance(homeLocation, b.store.location, distanceAccuracyInMeters);
            if (searchParams.sort === sortByFilterKeys.soonest) {
                if (collectionATime === collectionBTime) {
                    return collectionADistance - collectionBDistance;
                }
                return collectionATime - collectionBTime;
            }
            else if (searchParams.sort === sortByFilterKeys.closest) {
                if (collectionADistance === collectionBDistance) {
                    return collectionATime - collectionBTime;
                }
                return collectionADistance - collectionBDistance;
            }
            return 0;
        });
    }, [
        availableCollections,
        searchParams.sort,
        searchParams.type,
        searchParams.businessid,
        searchParams.storeid,
    ]);
    const shouldDisplayStoresDropdown = searchParams.businessid !== businessFilterKeys.all;
    useEffect(() => {
        if (businesses !== undefined && businesses.length !== 0) {
            const ongoingCollections = availableCollections.filter((collection) => collection.type === ONGOING).length;
            const adhocCollections = availableCollections.filter((collection) => collection.type === ADHOC).length;
            const temporaryCollections = availableCollections.filter((collection) => collection.type === TEMPORARY).length;
            trackEvent(LOAD_AVAILABLE_COLLECTIONS, {
                businesses: businesses.length,
                availableCollections: availableCollections.length,
                ongoingCollections,
                adhocCollections,
                temporaryCollections,
            });
        }
    }, [businesses]);
    useEffect(() => {
        dispatch(fetchMyInductions());
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(Stack, { gap: "1.5rem", children: [_jsxs(Inline, { gap: "1.125rem", children: [_jsxs("div", { children: [_jsx("label", { htmlFor: "sort-option", children: _jsx("span", { children: t('collection_slots.index.labels.sort_by') }) }), _jsx(Dropdown, { name: "sort-option", onChange: onSortOptionChange, value: searchParams.sort, options: [
                                        {
                                            value: sortByFilterKeys.soonest,
                                            label: t('collection_slots.index.sort_options.soonest'),
                                        },
                                        {
                                            value: sortByFilterKeys.closest,
                                            label: t('collection_slots.index.sort_options.closest'),
                                        },
                                    ] })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: "type-option", children: _jsx("span", { children: t('collection_slots.index.labels.collection_type') }) }), _jsx(Dropdown, { name: "type-option", onChange: onCollectionTypeChange, value: searchParams.type, options: [
                                        {
                                            value: collectionTypeFilterKeys.ALL,
                                            label: t('collection_slots.index.type_filter.default'),
                                        },
                                        ...collectionTypeFilterOptions,
                                    ] })] }), _jsxs("div", { children: [_jsx("label", { htmlFor: "business-option", children: _jsx("span", { children: t('collection_slots.index.labels.business') }) }), _jsx(Dropdown, { name: "business-option", onChange: onBusinessChange, value: searchParams.businessid, options: [
                                        {
                                            value: businessFilterKeys.all,
                                            label: t('collection_slots.index.business_filter.default'),
                                        },
                                        ...businessFilterOptions,
                                    ] })] }), shouldDisplayStoresDropdown && (_jsxs("div", { children: [_jsx("label", { htmlFor: "store-option", children: _jsx("span", { children: t('collection_slots.index.labels.store') }) }), _jsx(Dropdown, { name: "store-option", onChange: onStoreChange, value: searchParams.storeid, options: [
                                        {
                                            value: storeFilterKeys.all,
                                            label: t('collection_slots.index.store_filter.default'),
                                        },
                                        ...storeFilterOptions,
                                    ] })] }))] }), filteredAndSortedCollections.length > 0 && (_jsx(Grid, { columns: isMobile === true ? 1 : 2, gap: "1rem", children: _jsx(AnimatePresence, { children: filteredAndSortedCollections.map((collection) => {
                            const { business, store } = collection;
                            if (store?.location === undefined || business === undefined) {
                                return null;
                            }
                            return (_jsx(AvailableCollectionsListCard, { collectionId: Number(collection.collectionId), storeName: store?.name, businessName: business?.name, storeAddress: store?.address, type: collection.type, frequency: collection.frequency, nextCollection: collection.nextCollection, storeLocation: store?.location, collectionNotes: collection?.notes, storeTimezone: store?.timezone, donationConfirmedAt: collection.donationConfirmedAt }, collection.collectionId));
                        }) }) })), filteredAndSortedCollections.length <= 4 && (_jsx(Grid, { className: clsx(filteredAndSortedCollections.length !== 0 && 'no-collections-border'), columns: isMobile === true ? 1 : 2, gap: "1rem", children: _jsx(NoCollectionsNearMe, { collectionCount: filteredAndSortedCollections.length }) }))] }) }));
};
export default AvailableCollectionsList;
